import styled from "styled-components";
import Color from 'color';
import { CONTAINER, getMediaQuery, getVerticalSpacingAsStyle, GRID, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const Title = styled.h1`
  ${getTypography(Type.HEADING_1)}
  color: ${({ theme }) => theme.foreground.primary};
  text-align: center;
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`;

export const Container = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, VerticalSpacingVariant.LARGE)}
`

export const Content = styled.div`
  ${GRID}
`

export const Date = styled.p`
  ${getTypography(Type.LABEL)}
  color: ${({ theme }) => Color(theme.foreground.secondary).alpha(0.5)};
  text-align: center;
  grid-column: span 4;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, VerticalSpacingVariant.SMALL,{bottom:false})}

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 12;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 3 / span 8;
  }
`
