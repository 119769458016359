import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import { concat, get, map } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { SimpleBody } from '../components/simple/body';
import { SimpleHeader } from '../components/simple/header';


export const query = graphql`
  query PageLegalQuery($id: String!, $originalId: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageLegal(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      body {
        value
      }
    }
    versions: allDatoCmsPageLegal(
      filter: {slug: {ne: null}, originalId: {eq: $originalId}}
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
  }
`;

const PageLegal = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  
  const versions = get(data, 'versions.nodes');

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });


  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <SimpleHeader title={page.title} date={page.meta.updatedAtFormatted} />
      <SimpleBody content={page.body} />
    </Fragment>
  );
};

export default PageLegal;