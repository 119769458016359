import styled from "styled-components";
import { CONTAINER, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsStyle, GRID, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const Container = styled.div`
  ${CONTAINER}
`

export const Content = styled.div`
  ${GRID}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, VerticalSpacingVariant.LARGE, {top:false})}

  >p, >ol, >ul {
    ${getTypography(Type.BODY_REGULAR)}
    grid-column: span 4;

    +p, +ol, +ul{
      margin-top: ${getUnitAsPixels({multiplier:2})};
    }

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      grid-column: 1 / span 12;
    }
    
    ${getMediaQuery(ScreenSize.LARGE)}{
      grid-column: 3 / span 8;

      +p, +ol, +ul{
        margin-top: ${getUnitAsPixels({multiplier:3})};
      }
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      grid-column: 4 / span 6;
    }

    &:first-child{
      margin-top: 0;
    }

    strong{
      font-weight: 700;
    }

    em{
      font-style: italic;
    }

    a {
      color: ${({theme}) => theme.foreground.primary};
      text-decoration-color: ${({theme}) => theme.foreground.tertiary};
      transition: text-decoration-color 0.3s;

      &:hover{
        text-decoration-color: ${({theme}) => theme.foreground.primary};
      }
    }
  }

  >ol {
    padding-inline-start: ${getUnitAsPixels({multiplier:4})};
    list-style: auto;

    >li {
      padding-inline-start: ${getUnitAsPixels()};
      
      &::marker{
        color: ${({theme}) => theme.foreground.tertiary};
      }
    }
  }

  >ul {
    padding-inline-start: ${getUnitAsPixels({multiplier:4})};
    list-style: disc;

    >li {
      padding-inline-start: ${getUnitAsPixels()};

      &::marker{
        color: ${({theme}) => theme.foreground.tertiary};
      }
    }
  }

  >h1, >h2, >h3, >h4, >h4, >h5, >h6 {
    grid-column: span 4;

    +p, +ol, +ul{
      margin-top: ${getUnitAsPixels({multiplier:1})};
    }

    ${getMediaQuery(ScreenSize.MEDIUM)}{
      grid-column: 1 / span 12;
    }
    
    ${getMediaQuery(ScreenSize.LARGE)}{
      margin-top: ${getUnitAsPixels({multiplier:3})};
      grid-column: 3 / span 8;

      +p, +ol, +ul{
        margin-top: ${getUnitAsPixels({multiplier:2})};
      }
    }

    ${getMediaQuery(ScreenSize.XLARGE)}{
      grid-column: 4 / span 6;
    }

    &:first-child{
      margin-top: 0;
    }
  }

  >h1, >h2 {
    ${getTypography(Type.HEADING_3)}
    margin-top: ${getUnitAsPixels({multiplier:4})};

    +p, +ol, +ul{
      margin-top: ${getUnitAsPixels({multiplier:2})};

    }
  }

  >h3 {
    ${getTypography(Type.HEADING_4)}
    margin-top: ${getUnitAsPixels({multiplier:3})};

    +p, +ol, +ul{
      margin-top: ${getUnitAsPixels({multiplier:2})};
    }
  }

  >h4, >h4, >h5, >h6 {
    ${getTypography(Type.BODY_REGULAR)}
    font-weight: 700;
    margin-top: ${getUnitAsPixels({multiplier:2})};

    +p, +ol, +ul{
      margin-top: ${getUnitAsPixels({multiplier:1})};
    }
  }
`;