import React from 'react';
import { StructuredText } from 'react-datocms';

import { Container, Content, Inner} from './body.styles';
import { SimpleBodyProps } from './body.types';

export const SimpleBody = ({content}:SimpleBodyProps) => {
  return (
    <Container>
      <Content>
        <StructuredText
          data={content}
          renderBlock={({ record }) => {
            return null;
          }}
        />
      </Content>
    </Container>
  )
};