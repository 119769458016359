import { find } from 'lodash';
import get from 'lodash/get';
import { getAuthorPath } from './link';

const createSchema = () => {
  return {
    '@context': 'http://schema.org',
  };
};

const appendImage = (obj: any, image: string) => {
  if(image){
    const path = image.split("?")[0]
    obj['image'] = [
      `${path}?w=1200&ar=1:1&fit=crop`,
      `${path}?w=1200&ar=4:3&fit=crop`,
      `${path}?w=1200&ar=16:9&fit=crop`,
      
    ]
  }
};

const appendPublisherLogo = (obj: any, siteURL: any) => {
  if (siteURL) {
    obj['logo'] = {
      '@type': 'ImageObject',
      width: '600',
      height: '60',
      url: `${siteURL}/images/publisher-logo.png`,
    };
  }
};

const appendPublisher = (obj: any, siteName: any) => {
  if (siteName) {
    obj['publisher'] = {
      '@type': 'Organization',
      name: siteName,
    };

    // appendPublisherLogo(obj['publisher'], siteURL);
  }
};

const appendModified = (obj: any, pageModified: any) => {
  if (pageModified) {
    obj['dateModified'] = pageModified;
  }
};

const appendCreated = (obj: any, pageCreated: any) => {
  if (pageCreated) {
    obj['datePublished'] = pageCreated;
  }
};

const appendAuthors = (obj: any, authors: Array<{slug:string,name:string}>, siteName, siteURL, locale ) => {
  
  obj['author'] = [];

  if (authors && authors.length > 0) {
    authors.forEach(({slug,name}) => {
      obj['author'].push({
        "@type": "Person",
        "name": name,
        "url": siteURL + getAuthorPath({slug,locale})
      })
    })
  }
  else{
    obj['author'].push({
      "@type": "Organization",
      "name": siteName,
      "url": siteURL
    })
  }
};

const appendHeadline = (obj: any, headline: any) => {
  if (headline) {
    obj['headline'] = headline;
  }
};

const appendURL = (obj: any, url: string) => {
  obj['url'] = url;
};

const appendName = (obj: any, pageTitle: any) => {
  if (pageTitle) {
    obj['name'] = pageTitle;
  }
};

const appendDescription = (obj: any, pageDescription: any) => {
  if (pageDescription) {
    obj['description'] = pageDescription;
  }
};

const appendMainEntity = (obj: any, url: string) => {
  if (url) {
    obj['mainEntityOfPage'] = url;
  }
};

const appendLanguage = (obj: any, languageCode: any) => {
  if (languageCode) {
    obj['inLanguage'] = {
      '@type': 'Language',
      name: languageCode,
    };
  }
};

const appendType = (obj: any, type: any) => {
  if (type) {
    obj['@type'] = type;
  }
};

export const getSchema = (props: any) => get(props,"page.model.apiKey") === "article" ? articleSchema(props) : pageSchema(props);

export const pageSchema = ({ settings, site, page, pageContext }: any) => {
  const title = get(find(get(page,"seoMetaTags.tags",[]), ['attributes.property', "og:title"]),"attributes.content");
  const description = get(find(get(page,"seoMetaTags.tags",[]), ['attributes.name', "description"]),"attributes.content");
  const image = get(find(get(page,"seoMetaTags.tags",[]), ['attributes.property', "og:image"]),"attributes.content");
  
  if (page.seo && page.seo) {
    const schema = createSchema();
    appendType(schema, 'WebPage');
    appendLanguage(schema, get(page,"locale"));
    appendURL(schema, get(settings,"siteUrl"));
    appendName(schema, title);
    appendDescription(schema, description);
    appendCreated(schema, get(page,"meta.firstPublishedAt"));
    appendModified(schema, get(page,"meta.updatedAt"));
    appendImage(schema, image);

    return schema;
  }

  return null;
};

export const articleSchema = ({ settings, site, page, pageContext }: any) => {
  const title = get(find(get(page,"seoMetaTags.tags",[]), ['attributes.property', "og:title"]),"attributes.content");
  const description = get(find(get(page,"seoMetaTags.tags",[]), ['attributes.name', "description"]),"attributes.content");
  const image = get(find(get(page,"seoMetaTags.tags",[]), ['attributes.property', "og:image"]),"attributes.content");

  if (page.meta) {
    const schema = createSchema();
    appendType(schema, 'BlogPosting');
    appendLanguage(schema, get(page,"locale"));
    appendURL(schema, get(settings,"siteUrl"));
    appendName(schema, title);
    appendDescription(schema, description);
    appendCreated(schema, get(page,"meta.firstPublishedAt"));
    appendModified(schema, get(page,"meta.updatedAt"));
    appendImage(schema, image);
    appendMainEntity(schema,get(settings,"siteUrl"));
    appendAuthors(schema,page.authors,get(site,"globalSeo.siteName"),get(settings,"siteUrl"),pageContext);
    appendHeadline(schema,title);
    appendPublisher(schema,get(site,"globalSeo.siteName"));

    return schema;
  }

  return null;
};