import React from 'react';
import { SimpleHeaderProps } from '.';

import { Container, Content, Title, Date } from './header.styles';

export const SimpleHeader = ({title,date}:SimpleHeaderProps) => {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        {date && <Date>{date}</Date>}
      </Content>
    </Container>
  )
};